<script setup lang="ts">
const props = defineProps({
  links: {
    type: Object,
    required: true,
  },
  showSidebar: {
    type: Boolean,
    value: false
  }
});

const emit = defineEmits(['toggleSidebar'])

// Access the auth store
const authStore: any = useAuthStore()

// Get the user from the store
const user = computed(() => authStore.user)

// OPEN MODAL
const isOpen: Ref<boolean> = ref(false)
const isLoggingOut: Ref<boolean> = ref(false)

// Hide sidebar
const hideSidebar = () => {
    emit('toggleSidebar', false)
}

const logout = async () => {
    isLoggingOut.value = true
    try {
        // Call the authenticate action
        await authStore.signOut()
    } finally {
        isLoggingOut.value = false
    }
}
// Show Philips logo for users with customers from the following countries
const showPhilipsLogo: Function = (): boolean => {
    const countries = ['DK', 'FI', 'SE', 'NO']
    return user.value.customer && user.value.customer.country && countries.includes(user.value.customer.country.iso2)
}
</script>

<template>
    <div class="fixed top-0 bottom-0 md:left-auto z-10 bg-white w-full sm:w-80 md:w-auto transition-all duration-300 sidebar" :class="{ 'left-0' : props.showSidebar, '-left-full': !props.showSidebar}">
        <div class="absolute w-full md:w-80 md:-left-80 bg-white flex flex-col h-full border-e border-solid border-[--color-border-gray] px-2 py-4 md:px-4 md:py-8">

            <button 
                type="button" 
                class="md:hidden absolute right-4 top-6 text-[--color-primary-text]"
                @click="hideSidebar"
            >
                <span class="icon ralph-cross"></span>
            </button>

            <div class="flex-col items-start gap-2 sm:flex-row sm:pr-10 md:pr-0 flex mb-8">
                <NuxtLink :to="localePath('/dashboard')" class="block">
                    <img class="h-12 w-auto" src="/img/new_ralph_logo.svg" alt="Your Company" />
                </NuxtLink>
    
                <img 
                    src="/img/philips_logo.svg" 
                    alt="Philips Logo" 
                    class="pl-[1.25rem] h-[70px] block" 
                    v-if="showPhilipsLogo()"
                />
            </div>
            
            <UVerticalNavigation :links="links" />

             <div class="mt-auto border-t border-solid border-[--color-border-gray] flex pt-5 px-1">
                <div class="flex-1 flex" v-if="Object.keys(user).length > 0">
                    <NuxtLink :to="localePath(`/dashboard/profile`)" v-if="user.first_name">
                       <avatar 
                        :username="user.first_name + ' ' + user.last_name" 
                        :image="user.profile_image"
                        />
                    </NuxtLink>
    
                   <div class="flex flex-col px-3 text-sm break-all">
                        <NuxtLink :to="localePath(`/dashboard/profile`)" class="font-bold text-[--color-dark-text]">
                            {{ user.first_name + ' ' + user.last_name }}
                        </NuxtLink>
                        <NuxtLink :to="localePath(`/dashboard/profile`)" class="font-light text-gray-600">
                            {{ user.email }}
                        </NuxtLink>
                   </div>
                </div>
                <div class="flex-1 flex" v-else>
                   <USkeleton class="h-10 w-10" :ui="{ rounded: 'rounded-md' }" />

                   <div class="flex flex-col px-3 gap-1 text-sm break-all">
                        <USkeleton class="h-4 w-32" />
                        <USkeleton class="h-4 w-40" />
                   </div>
                </div>
                
                <div class="w-5 relative">
                    <button class="text-gray-500 icon ralph-signout hover:bg-green-100 p-1 rounded-md text-sm absolute right-0 top-0" @click="isOpen = true"></button>
    
                    <UModal 
                    v-model="isOpen" 
                    :ui="{ base: 'sm:max-w-md' }">
                        <div class="px-8 py-6">
                            <div class="relative flex items-center mb-6">
                            <img src="/img/bg_pattern.svg" class="absolute -top-6 -left-6 z-0">
    
                            <div class="rounded-full bg-green-200/20 w-12 h-12 flex justify-center items-center mr-3">
                                <span class="icon ralph-signout text-xl text-[--color-primary]"></span>
                            </div>
    
                            <button class="icon ralph-cross ml-auto text-sm text-gray-500 focus:outline-none -top-5 -right-2 relative" @click="isOpen = false"></button>
                            </div> 
    
                            <div class="relative">
                                <h3 class="relative font-medium text-lg sm:text-xl mb-4 sm:mb-8">
                                    {{ $t('auth.logout_message') }}
                                </h3>
    
                                <div class="flex gap-2">
                                    <button 
                                        @click="isOpen = false" 
                                        class="flex-1 rounded-md px-3 py-2.5 border hover:bg-indigo-500 hover:text-white"
                                        :class="{ 'opacity-50 cursor-not-allowed': isLoggingOut }"
                                        :disabled="isLoggingOut"
                                    >
                                        {{ $t('buttons.cancel') }}
                                    </button>
    
                                    <button 
                                        @click="logout" 
                                        class="flex-1 rounded-md bg-[--color-primary] px-3 py-2.5 text-white hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                        :class="{ 'opacity-50 cursor-not-allowed': isLoggingOut }"
                                        :disabled="isLoggingOut"
                                    >
                                        <Loader className="w-3 h-3" v-if="isLoggingOut" />
                                        {{ $t('buttons.logout') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </UModal>
                </div>
            </div>
        </div>
    </div>
</template>